<style scoped lang="scss">

.sidebar {
  --sidebar-width: 76px;

  width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  white-space: nowrap;
  background-color: $gray-light-4;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $gray-light;
  padding-block: 20px;
  transition: width .1s ease-in-out, min-width .1s ease-in-out;

  &.expanded {
    --sidebar-width: 236px;
  }

  .control,
  .separator-icon {
    height: 40px;
  }

  .control{
    cursor: pointer;
    color: $gray-2;

    &:hover {
      color: $orange;

      .gac {
        ::v-deep .inside {
          fill: $orange;
        }
      }
    }

    .collapse-icon {
      margin-inline: 28px 8px;
    }

    .gac {
      width: 38px;

      ::v-deep .inside {
        fill: $gray-3;
      }
    }
  }

  .separator-icon {
    .icon-container {
      width: 24px;
      aspect-ratio: 1;
      margin-inline: 28px 8px;

      .icon {
        font-size: 12px;
        color: $gray-light;
      }
    }
  }

  .sections {
    height: 100%;

    .separator {
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: $gray-2;
      margin-left: 28px;
      height: 40px;
    }
  }
}

</style>


<template lang="pug">

aside.sidebar(:class="{ expanded }")
  .sections.flex.space-between.column-direction
    .top
      .control.flex.vertical-center(@click="toggleExpanded")
        template(v-if="expanded")
          sidebar-icon.collapse-icon(icon="fa-arrow-to-left")
          gac-icon.gac.no-shrink

        template(v-else)
          sidebar-icon.collapse-icon(icon="fa-arrow-to-right")

      sidebar-item(
        icon="fa-plus",
        :expanded="expanded",
        :label="$t('.sections.search')",
        :active="['search']",
        :to="{ name: 'search' }"
      )

      sidebar-item(
        icon="fa-headset",
        :expanded="expanded",
        :label="$t('.sections.see-all')",
        :active="['tickets']",
        :to="{ name: 'tickets' }"
      )

      sidebar-item(
        v-if="user !== null && (user.email === 'leandrosantana@movida.com.br' || user.email === 'wagnerrosa@movida.com.br' || user.email === 'eduardonavarro@movida.com.br')",
        icon="fa-circle-parking",
        :expanded="expanded",
        :label="$t('.sections.params')",
        :active="['params']",
        :to="{ name: 'params' }"
      )
</template>


<script>
import { auth } from "@/modules/auth"

// Components
import SidebarItem from "@/components/sidebar-item/sidebar-item"
import SidebarIcon from "@/components/sidebar-icon/sidebar-icon"
import GacIcon from "@/components/icons/gac-icon/gac-icon.vue"

export default {
  name: "Sidebar",

  components: {
    SidebarItem,
    SidebarIcon,
    GacIcon
  },

  data() {
    return {
      i18nScope: "components.sidebar",
      expanded: false,
      user: auth.getUser() ?? null,
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    }
  }
}

</script>
